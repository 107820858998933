import React, { useState } from 'react';

export function SocialIcons() {
  const [isWork, setIsWork] = useState(true);
  const [fadeIn, setFadeIn] = useState(null);
  const [fadeOut, setFadeOut] = useState(null);

  function personalSocialMedias() {
    let style = isWork ? "hidden fadeOut" : "fadeIn";
    return (
      <span id="social-medias" className={style}>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/_simonluu/">
            <i className="icon fa fa-instagram fa-lg instagram" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/dshadowguy">
            <i className="icon fa fa-twitter fa-lg twitter" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.twitch.tv/delixium">
            <i className="icon fa fa-twitch fa-lg twitch" aria-hidden="true"></i>
          </a>
        </li>
      </span>
    );
  }

  function workSocialMedias() {
    let style = isWork ? "fadeIn" : "hidden fadeOut";
    return (
      <span id="work-medias" className={style}>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/simon-luu-a8415112a">
            <i className="icon fa fa-linkedin fa-lg linkedin" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://github.com/simonluu">
            <i className="icon fa fa-github fa-lg github" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="mailto:simon.luu.dev@gmail.com">
            <i className="icon fa fa-envelope fa-lg email" aria-hidden="true"></i>
          </a>
        </li>
      </span>
    );
  }

  return (
    <ul id="social-items">
      {workSocialMedias()}
      {personalSocialMedias()}
      <div id="more-socials" onClick={() => {
        setIsWork(!isWork);
        setFadeIn(!fadeIn);
        setFadeOut(!fadeOut);
      }}>...</div>
    </ul>
  );
}