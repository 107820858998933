export default {
	"shengjiApp": {
		"github": "https://github.com/simonluu/shengji-app",
		"image": "../../images/playing-card.jpg",
		"name": "Shengji App",
		"previewImage": "../../images/shengji_image.png",
		"description": "This application is an online version of a playing card game called Shengji. Currently trying to set up a domain for this application. This project was created using React Redux, Node, Express and MongoDB."
	},
	"hearthstonePackSimulator": {
		"link": "https://hearthstone-pack-simulator.herokuapp.com/",
		"github": "https://github.com/simonluu/hearthstone-pack-simulator",
		"image": "../../images/hearthstonepack.jpg",
		"name": "Hearthstone Pack Simulator",
		"previewImage": "../../images/hspacksim_image.png",
		"description": "This application is a Hearthstone Pack Simulator. It simulates pack opening from the game Hearthstone. This project was created using React Redux and uses axios to grab information from HearthstoneAPI."
	},
	"twitchStreamers": {
		"github": "https://github.com/simonluu/twitchstreamers",
		"image": "../../images/Twitch-Icon.png",
		"name": "List of Twitch Streamers",
		"previewImage": "../../images/twitch_stream_image.png",
		"description": "This application is a list of Twitch Streamers. You can search for a list of twitch streams categorized by games. This is made using React Redux and the Twitch API."
	},
	"cloneReddit": {
		"link": "https://cloneforreddit.herokuapp.com/",
		"github": "https://github.com/simonluu/clone-for-reddit",
		"image": "../../images/no_image.gif",
		"name": "React Clone for Reddit",
		"previewImage": "../../images/redditClone_image.png",
		"description": "This application is me trying to learn to build applications in React Redux. I tried to replicate reddit using the Reddit API. This project is still in the works."
	},
	"recipeBook": {
		"github": "https://github.com/simonluu/recipe-book",
		"image": "../../images/recipe-book.jpg",
		"name": "Recipe-Book",
		"previewImage": "../../images/recipebook_image.png",
		"description": "This application is a Recipe Book. I created it while learning Angular 2 with the help of Maximilian Schwarzmüller and his course on Udemy. I added a few features like authentication with email and password. This application is built with Angular 2 and Firebase."
	},
	"reactVideoSearch": {
		"github": "https://github.com/simonluu/react-video-search",
		"image": "../../images/videosearch.png",
		"name": "React Video Searcher",
		"previewImage": "../../images/reactvideosearcher_image.png",
		"description": "The video searcher application searches the youtube database for videos that the user inputs in the search bar. I created this app following Stephen Grider's React Redux tutorial at Udemy. I built this project using React Redux and the Youtube API."
	},
	"freezeMageCalculator": {
		"link": "https://freeze-mage-calculator.herokuapp.com/",
		"image": "../../images/mage_13.png",
		"name": "Freeze Mage Calculator",
		"previewImage": "../../images/freezemage_image.png",
		"description": "This web application calculates if you have lethal with the given spells in a game of Hearthstone (Wild Only). This project is built using ReactJS."
	}
}