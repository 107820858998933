import React, { useState } from 'react';
import './App.css';

import { SocialIcons } from './components/social_icons';
import { About } from './components/about';
import Projects from './components/projects';
import useWindowDimensions from './helpers/window';

function openNav() {
  let nav = document.getElementById("mobile-nav-bar-items");
  let project = document.getElementById("project");
  let about = document.getElementById("about");
  if (nav.style.display === "block") {
    nav.style.display = "none";
    project.style.marginTop = "50px";
    about.style.marginTop = "200px";
  } else {
    nav.style.display = "block";
    project.style.marginTop = "200px";
    about.style.marginTop = "225px";
  }
}

function App() {
  const [isSelected, setIsSelected] = useState({
    homeSelected: true,
    aboutSelected: false,
    projectSelected: false
  });
  const { height, width } = useWindowDimensions();
  let mobileNavDisplay = 'none';
  let navDisplay = 'block';
  if (width <= 470) {
    navDisplay = 'none';
    mobileNavDisplay = 'block';
  }

  return (
    <div className="App">
      <header id="nav-bar">
        <div style={{display: navDisplay}}>
          <ul id="nav-bar-items">
              <span>
                <li onClick={() => {
                  setIsSelected({
                    homeSelected: true,
                    aboutSelected: false,
                    projectSelected: false
                  })
                }}>Home</li>
                <li onClick={() => {
                  setIsSelected({
                    homeSelected: false,
                    aboutSelected: true,
                    projectSelected: false
                  })
                }}>About</li>
                <li onClick={() => {
                  setIsSelected({
                    homeSelected: false,
                    aboutSelected: false,
                    projectSelected: true
                  })
                }}>Projects</li>
              </span>
            </ul>
        </div>
        <div id="mobile-nav" style={{display: mobileNavDisplay}}>
          <i onClick={() => {openNav()}} className="fa fa-bars"></i>
            <ul id="mobile-nav-bar-items">
              <div>
                <li onClick={() => {
                  setIsSelected({
                    homeSelected: true,
                    aboutSelected: false,
                    projectSelected: false
                  })
                }}>Home</li>
                <li onClick={() => {
                  setIsSelected({
                    homeSelected: false,
                    aboutSelected: true,
                    projectSelected: false
                  })
                }}>About</li>
                <li onClick={() => {
                  setIsSelected({
                    homeSelected: false,
                    aboutSelected: false,
                    projectSelected: true
                  })
                }}>Projects</li>
              </div>
            </ul>
          </div>
      </header>
      <main id="main">
        <div className="App-body">
          <div id="home" className={isSelected.homeSelected ? "fadeIn" : "hidden fadeOut"}>
            <h1>Simon Luu</h1>
            <h3>Full Stack Web Developer</h3>
            <h6 id="learn-more" onClick={() => {
                setIsSelected({
                  homeSelected: false,
                  aboutSelected: true,
                  projectSelected: false
                })
              }}>Learn More</h6>
          </div>
          <div id="about" className={isSelected.aboutSelected ? "fadeIn" : "hidden fadeOut"}>
            <About setIsSelected={setIsSelected} />
          </div>
          <div id="project" className={isSelected.projectSelected ? "fadeIn" : "hidden fadeOut"} style={{}}>
            <Projects />
          </div>
          <canvas id="canvas"></canvas>
        </div>
      </main>
      <footer id="footer">
        <SocialIcons />
        <i className="fa fa-copyright" aria-hidden="true"> 2021 Simon Luu</i>
      </footer>
    </div>
  );
}

export default App;
