import React from 'react';

export function About(props) {
  return (
    <div>
      <section>
        <section>
          My name is Simon Luu. I am a Full Stack Developer living in Baltimore, Maryland. I am currently working as an Applications
          Developer at FEi Systems. My main focus on React Redux Applications. I enjoy building web applications and browser games. 
          Outside of programming, I enjoy playing video games, board games, climbing, unicycling, and slacklining.
        </section>
        <section>
          My skillset includes:
            <ul>
              <li>Languages: React, Redux, JavaScript, C#, Python, NodeJS</li>
              <li>Databases: PostgreSql, MongoDB</li>
              <li>Paradigms: REST APIs, MVC, Git, .NET</li>
            </ul>
        </section>
      </section>
      <section>
        Check out my <span id="projectspan" onClick={() => {
          props.setIsSelected({
            homeSelected: false,
            aboutSelected: false,
            projectSelected: true
          })
        }}>projects</span> and <a href="./pdf/websiteresume.pdf" download="resume">résumé</a>.
      </section>
    </div>
  );
}